.query-display {
  background-color: transparent;
  backdrop-filter: blur(2px);
  width: 40%;
  margin: 2%;
  padding: 2%;
}
.heading {
  font-size: 2rem;
  font-family: Arial, Helvetica, sans-serif;
  color: #3b436e;
}
.query-form {
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
}
.query-form label {
  color: #3b436e;
  font-size: 1.2rem;
}
.query-form button {
  color: #3a8a4d;
  font-size: 1.2rem;
  width: 20%;
  min-width: 100px;
  height: 4.5vh;
  margin-bottom: 3%;
  border: 1px solid #3a8a4d;
}
.query-form input[type="text"] {
  width: 40%;
  height: 2vh;
  margin-bottom: 1%;
  border: 1px solid black;
}
.query-form textarea {
  width: 80%;
  height: 10vh;
  margin: 1% 0 2% 0;
  font-size: 1.3rem;
  /* border-radius: 10px; */
}
.contact-display {
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
}

.contact-info {
  display: flex;
  margin: auto;
  width: 90%;
}

.contact-info > div {
  width: 50%;
  padding: 2%;
  /* height: 30vh; */
}
.address-card {
  display: flex;
  width: 40vw;
  height: 7vh;
  margin: 0.5vh;
  padding: 0.5%;
  align-items: center;
}
.address-card .left-box {
  width: 8%;
}
.address-card .right-box {
  width: 92%;
  padding: 1% 2%;
  color: #3b436e;
  font-size: 1.1rem;
}
.map-display {
  display: flex;
  justify-content: center;
}
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  font-size: 1.2rem;
  padding: 1%;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-size: 1.2rem;
  padding: 1%;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  padding: 1%;
  font-size: 1.2rem;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  padding: 1%;
  font-size: 1.2rem;
}

@media screen and (max-width: 600px) {
  .query-display {
    width: 90%;
  }
  .heading {
    font-size: 1 rem;
  }
  .contact-info {
    flex-wrap: wrap;
  }
  .contact-info > div {
    width: 100%;
  }
  .address-card {
    width: 100%;
  }
}
