.slider-display {
  margin: auto;
  overflow: hidden;
  position: relative;
}
.slider-container {
  height: 100%;
  width: 100%;
  display: flex;
  background-size: contain;
  background-position-x: center;
  background-repeat: no-repeat;
}
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  font-weight: 900;
  color: grey;
  cursor: pointer;
  opacity: 0.4;
}
.left-arrow {
  left: 0.5vw;
}
.right-arrow {
  right: 0.5vw;
}
.slider-img-container {
  width: 70%;
}
.slider-info-container {
  width: 30%;
  padding: 1%;
  align-self: center;
  margin-bottom: 3vh;
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.slider-info-container a {
  align-self: center;
  width: fit-content;
  margin: auto;
}
@media screen and (max-width: 600px) {
  .slider-container {
    flex-direction: column;
  }
  .slider-img-container {
    width: 100%;
    height: 60%;
  }
  .slider-info-container {
    font-size: 1rem;
    width: 100%;
    height: 60%;
    padding: 0 2%;
    margin: 0;
  }
}
@media screen and (max-width: 400px) {
  .slider-container {
    flex-direction: column;
  }
  .slider-img-container {
    width: 100%;
    height: 60%;
  }
  .slider-info-container {
    font-size: 0.8rem;
    width: 100%;
    height: 60%;
    padding: 0 2%;
    margin: 0;
  }
}
