body {
  margin: 0;
}
#navbar {
  display: flex;
  justify-content: space-between;
  padding: 1% 0.5%;
  height: 3vh;
  align-items: center;
  width: 99%;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 2;
}
#logo {
  display: flex;
  align-items: center;
}
#logo img {
  height: 50px;
}
#company-name {
  margin-left: 0.35vw;
  color: #3a8a4d;
  font-size: 2.8rem;
  font-weight: 550;
  font-family: "Carattere", cursive;
}
#nav-items ul {
  list-style: none;
  display: flex;
}
#nav-items ul li {
  margin-right: 2vw;
}
.nav-item > a {
  font-size: 2rem;
  color: #444b71;
  font-family: serif;
}
.nav-item:hover {
  cursor: pointer;
}
.nav-item > a:hover {
  color: #3a8a4d;
}

/* Media queries */

@media screen and (max-width: 600px) {
  .nav-item > a {
    font-size: 1.2rem;
  }
  #logo img {
    height: 2rem;
    align-self: center;
  }
  #company-name {
    font-size: 2rem;
    align-self: center;
  }
  #navbar {
    padding-top: 2%;
  }
}

@media screen and (max-width: 400px) {
  .nav-item > a {
    font-size: 0.8rem;
  }
  #logo img {
    height: 1.5rem;
    align-self: center;
  }
  #company-name {
    font-size: 1.5rem;
    align-self: center;
  }
  #navbar {
    padding-top: 1.5%;
  }
}

@media screen and (max-width: 300px) {
  .nav-item > a {
    font-size: 0.5rem;
  }
  #logo img {
    height: 1rem;
    align-self: center;
  }
  #company-name {
    font-size: 1rem;
    align-self: center;
  }
  #navbar {
    padding-top: 1%;
  }
}
