.app-spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #d6d6d6;
  border-radius: 50%;
  border-top-color: #6200ee;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
  margin-right: 10px;
  position: absolute;
  top: 45%;
  left: 50%;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
