section {
  font-family: serif;
}
section:nth-child(2n) {
  background-color: rgb(243, 243, 243);
  width: 95%;
  margin: auto;
  padding: 1%;
  margin: 1% auto;
}
.section-heading {
  font-size: 2rem;
  text-align: center;
  font-family: sans-serif;
  color: #3b436e;
}
.subheading {
  font-size: 1.5rem;
  text-align: center;
  font-family: sans-serif;
  color: #3b436e;
  margin: 2vh auto 0.5vh auto;
}
.subtext {
  color: rgb(84, 84, 84);
  text-align: justify;
  width: 95%;
  margin: auto;
  font-size: 1.2rem;
}
.bullets {
  width: 90%;
  padding: 0.5%;
}
#intro {
  padding-top: 7vh;
  padding-bottom: 1%;
}
.intro-about {
  width: 80%;
  margin: auto;
  font-size: 1.5rem;
  text-align: center;
  padding: 0 1%;
  animation-name: slide-and-fade;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  margin-top: 4vh;
}
#about-display {
  padding-bottom: 1%;
}
.cards-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 1%;
}
.cards-container > * {
  width: 26%;
}
.cards-container .card-data {
  text-align: justify;
}
.companies {
  display: flex;
  justify-content: space-between;
  padding: 1% 2%;
  width: inherit;
  flex-wrap: wrap;
}
.company-item {
  max-width: 10%;
  height: 5vh;
  min-width: 100px;
}
.clientel-display {
  margin: auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.testimonials {
  width: 50%;
  height: fit-content;
}
.testimonials-display {
  display: flex;
  height: 70vh;
  justify-content: center;
}
.testimonials-display > * {
  width: 50%;
}
.testimonials-display img {
  object-fit: contain;
  object-position: center;
}
.testimonial-right {
  display: flex;
  flex-direction: column;
  width: 30%;
}
.testimonial-right > * {
  height: 50%;
}
.testimonials-display img {
  transition: all 0.5s;
}
.testimonial-right img:hover {
  transform: scale(1.8);
}
.testimonial-left img:hover {
  transform: scale(1.5);
}
.major-clients {
  width: 50%;
}
.major-clients-display {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.major-clients-display ul {
  margin-left: 2%;
}
#performance-cert-btn {
  margin-top: 7%;
  align-self: center;
}
/* Media queries */
@media screen and (max-width: 1200px) {
  .cards-container > * {
    width: 40%;
  }
}
@media screen and (max-width: 1000px) {
  .cards-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding: 1%;
  }
  .cards-container > * {
    width: 50%;
  }
}

@media screen and (max-width: 600px) {
  #intro {
    padding-top: 5vh;
  }
  .intro-about,
  .intro-collage {
    width: 100%;
    padding: 0.5%;
    font-size: 1.2rem;
    margin-bottom: 1vh;
    font-size: 0.8rem;
  }
  .section-heading {
    font-size: 1.2rem;
  }
  .subheading {
    font-size: 1rem;
  }
  .subtext {
    font-size: 0.8rem;
  }
  .company-item {
    padding: 1%;
    margin: 1% auto;
    width: 18%;
    height: 5vh;
    min-width: 100px;
  }
  .clientel-display {
    flex-direction: column-reverse;
  }
  .clientel-display > * {
    width: 98%;
  }
  .cards-container > * {
    width: 80%;
  }
  .cards-container .card-data {
    font-size: 0.8rem;
  }
  .cards-container .card-button {
    font-size: 0.5rem;
  }
  .testimonials-display{
    height: 45vh;
  }
  
}

/* Animations */
@keyframes slide-and-fade {
  from {
    left: -3vw;
    opacity: 0;
    font-weight: 100;
  }
  to {
    left: 0;
    opacity: 1;
    font-weight: 400;
  }
}
